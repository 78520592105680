const url = window.location.origin;

const defaultMovies = {
  hero: {
    Title: 'Blade Runner',
    Year: '2017',
    Rated: 'R',
    Released: '06 Oct 2017',
    Runtime: '164 min',
    Genre: 'Action, Drama, Mystery',
    Director: 'Denis Villeneuve',
    Writer: 'Hampton Fancher, Michael Green, Philip K. Dick',
    Actors: 'Harrison Ford, Ryan Gosling, Ana de Armas',
    Plot: "Young Blade Runner K's discovery of a long-buried secret leads him to track down former Blade Runner Rick Deckard, who's been missing for thirty years.",
    Language: 'English, Finnish, Japanese, Hungarian, Russian, Somali, Spanish',
    Country: 'United States, United Kingdom, Canada, Hungary, Spain, Mexico',
    Awards: 'Won 2 Oscars. 99 wins & 158 nominations total',
    Poster: `${url}/images/default-movies/blade-runner-2049.jpg`,
    Ratings: [
      {
        Source: 'Internet Movie Database',
        Value: '8.0/10',
      },
      {
        Source: 'Rotten Tomatoes',
        Value: '88%',
      },
      {
        Source: 'Metacritic',
        Value: '81/100',
      },
    ],
    Metascore: '81',
    imdbRating: '8.0',
    imdbVotes: '503,357',
    imdbID: 'tt1856101',
    Type: 'movie',
    DVD: '16 Jan 2018',
    BoxOffice: '$92,054,159',
    Production: 'N/A',
    Website: 'N/A',
    Response: 'True',
  },
  popular: [{
    Title: 'The Death of Stalin',
    Year: '2017',
    Rated: 'R',
    Released: '09 Mar 2018',
    Runtime: '107 min',
    Genre: 'Comedy, Drama, History',
    Director: 'Armando Iannucci',
    Writer: 'Fabien Nury, Thierry Robin, Armando Iannucci',
    Actors: 'Steve Buscemi, Simon Russell Beale, Jeffrey Tambor',
    Plot: 'Moscow, 1953. After being in power for nearly thirty years, Soviet dictator Joseph Vissarionovich Stalin takes ill and quickly dies. Now the members of the Council of Ministers scramble for power.',
    Language: 'English',
    Country: 'United Kingdom, France, Belgium, Canada, United States',
    Awards: '18 wins & 40 nominations total',
    Poster: `${url}/images/default-movies/the-death-of-stalin.jpg`,
    Ratings: [
      {
        Source: 'Internet Movie Database',
        Value: '7.3/10',
      },
      {
        Source: 'Rotten Tomatoes',
        Value: '95%',
      },
      {
        Source: 'Metacritic',
        Value: '88/100',
      },
    ],
    Metascore: '88',
    imdbRating: '7.3',
    imdbVotes: '95,314',
    imdbID: 'tt4686844',
    Type: 'movie',
    DVD: '11 Jun 2018',
    BoxOffice: '$8,047,856',
    Production: 'N/A',
    Website: 'N/A',
    Response: 'True',
  }, {
    Title: 'Isle of Dogs',
    Year: '2018',
    Rated: 'PG-13',
    Released: '13 Apr 2018',
    Runtime: '101 min',
    Genre: 'Animation, Adventure, Comedy',
    Director: 'Wes Anderson',
    Writer: 'Wes Anderson, Roman Coppola, Jason Schwartzman',
    Actors: 'Bryan Cranston, Koyu Rankin, Edward Norton',
    Plot: "Set in Japan, Isle of Dogs follows a boy's odyssey in search of his lost dog.",
    Language: 'English, Japanese',
    Country: 'United States, Germany, United Kingdom',
    Awards: 'Nominated for 2 Oscars. 34 wins & 93 nominations total',
    Poster: `${url}/images/default-movies/isle-of-dogs.jpg`,
    Ratings: [
      {
        Source: 'Internet Movie Database',
        Value: '7.9/10',
      },
      {
        Source: 'Rotten Tomatoes',
        Value: '90%',
      },
      {
        Source: 'Metacritic',
        Value: '82/100',
      },
    ],
    Metascore: '82',
    imdbRating: '7.9',
    imdbVotes: '152,555',
    imdbID: 'tt5104604',
    Type: 'movie',
    DVD: '17 Jul 2018',
    BoxOffice: '$32,015,231',
    Production: 'N/A',
    Website: 'N/A',
    Response: 'True',
  }, {
    Title: 'Chernobyl',
    Year: '2019',
    Rated: 'TV-MA',
    Released: '06 May 2019',
    Runtime: '330 min',
    Genre: 'Drama, History, Thriller',
    Director: 'N/A',
    Writer: 'Craig Mazin',
    Actors: 'Jessie Buckley, Jared Harris, Stellan Skarsgård',
    Plot: "In April 1986, an explosion at the Chernobyl nuclear power plant in the Union of Soviet Socialist Republics becomes one of the world's worst man-made catastrophes.",
    Language: 'English, Russian, Ukrainian',
    Country: 'United States, United Kingdom',
    Awards: 'Won 10 Primetime Emmys. 83 wins & 57 nominations total',
    Poster: `${url}/images/default-movies/chernobyl.jpg`,
    Ratings: [
      {
        Source: 'Internet Movie Database',
        Value: '9.4/10',
      },
    ],
    Metascore: 'N/A',
    imdbRating: '9.4',
    imdbVotes: '624,241',
    imdbID: 'tt7366338',
    Type: 'series',
    totalSeasons: '1',
    Response: 'True',
  }, {
    Title: 'Alien',
    Year: '1979',
    Rated: 'R',
    Released: '22 Jun 1979',
    Runtime: '117 min',
    Genre: 'Horror, Sci-Fi',
    Director: 'Ridley Scott',
    Writer: "Dan O'Bannon, Ronald Shusett",
    Actors: 'Sigourney Weaver, Tom Skerritt, John Hurt',
    Plot: 'After a space merchant vessel receives an unknown transmission as a distress call, one of the crew is attacked by a mysterious life form and they soon realize that its life cycle has merely begun.',
    Language: 'English',
    Country: 'United Kingdom, United States',
    Awards: 'Won 1 Oscar. 18 wins & 22 nominations total',
    Poster: `${url}/images/default-movies/alien.jpg`,
    Ratings: [
      {
        Source: 'Internet Movie Database',
        Value: '8.4/10',
      },
      {
        Source: 'Rotten Tomatoes',
        Value: '98%',
      },
      {
        Source: 'Metacritic',
        Value: '89/100',
      },
    ],
    Metascore: '89',
    imdbRating: '8.4',
    imdbVotes: '827,245',
    imdbID: 'tt0078748',
    Type: 'movie',
    DVD: '01 Jun 1999',
    BoxOffice: '$81,900,459',
    Production: 'N/A',
    Website: 'N/A',
    Response: 'True',
  },
  {
    Title: 'Jojo Rabbit',
    Year: '2019',
    Rated: 'PG-13',
    Released: '08 Nov 2019',
    Runtime: '108 min',
    Genre: 'Comedy, Drama, War',
    Director: 'Taika Waititi',
    Writer: 'Christine Leunens, Taika Waititi',
    Actors: 'Roman Griffin Davis, Thomasin McKenzie, Scarlett Johansson',
    Plot: "A young German boy in the Hitler Youth whose hero and imaginary friend is the country's dictator is shocked to discover that his mother is hiding a Jewish girl in their home.",
    Language: 'English, German',
    Country: 'New Zealand, Czech Republic, United States',
    Awards: 'Won 1 Oscar. 48 wins & 194 nominations total',
    Poster: `${url}/images/default-movies/jojo-rabbit.jpg`,
    Ratings: [
      {
        Source: 'Internet Movie Database',
        Value: '7.9/10',
      },
      {
        Source: 'Rotten Tomatoes',
        Value: '80%',
      },
      {
        Source: 'Metacritic',
        Value: '58/100',
      },
    ],
    Metascore: '58',
    imdbRating: '7.9',
    imdbVotes: '345,279',
    imdbID: 'tt2584384',
    Type: 'movie',
    DVD: '08 Nov 2019',
    BoxOffice: '$33,370,906',
    Production: 'N/A',
    Website: 'N/A',
    Response: 'True',
  }],
};

export default defaultMovies;
